import {
  type CostCalculations,
  aggCostCalcs,
  calcCosts,
  zeroCostCalcs,
} from '../core';
import type { CalcReqCostsInput } from './zod';

export const calcRequirementCost = (
  reqCostsInput: CalcReqCostsInput,
): CostCalculations => {
  /*
   KNOWN LIMITATIONS/VARIATIONS:
     1. Doesn't account for scrap
     2. Doesn't account for last actual using existing inventory (causes actual
        cost to be lower/0 than what we might expect on the next run)
        - for estimates might be better to use supplier pricing
     3. Doesn't account for RM supplier quantity breaks
     4. Doesn't account for min when multiple reqs use the same material
        TODO: min should be handled higher up


    OPEN QUESTIONS:
       1. source for min purchase
       2. qty per type S vs E

   */

  const {
    //  usageUnitMeasure,
    qty,
    basisQty,
    //  perQtyType,
    matUnitCost,
    labUnitCost,
    burUnitCost,
    srvUnitCost,
    matMinCost, //TODO: see note above, might want to handle higher-up for multiple reqs in part/quote using same mat
    subOpsCosts,
  } = reqCostsInput;

  // run any with sub-operations
  if (subOpsCosts.length) {
    const opsTotals = subOpsCosts
      .map(calcCosts)
      .reduce(aggCostCalcs, zeroCostCalcs());
    return opsTotals;
  }

  //TODO: some quantities need to be whole numbers, some partial
  //    - issued qty is usually rounded up from calcQty?
  //  - also scrap has something to do with it sometimes

  // purchased mat cost
  const totalMatCost = matUnitCost * qty;
  const totalLabCost = labUnitCost * qty;
  const totalBurCost = burUnitCost * qty;
  const totalSrvCost = srvUnitCost * qty;

  return calcCosts({
    laborCost: totalLabCost,
    materialCost: Math.max(totalMatCost, matMinCost),
    serviceCost: totalSrvCost,
    burdenCost: totalBurCost,
  });
};
