/* eslint-disable @typescript-eslint/no-explicit-any */
// @TODO(NT): I don't even know...
// @SEE: https://mwcomponents.atlassian.net/browse/MW-870
import is from '@sindresorhus/is';
import { clone, get, set } from 'lodash-es';
import { keys as _keys } from 'radash';
import type { Entries, UnionToIntersection } from 'type-fest';
import type { FieldPath, FieldPathValue } from './path-types';
import type { FieldValues } from './path-types/utils';

/** Just a typed shallow Object.keys */
export const keys = <T extends Record<string, any>>(obj: T): (keyof T)[] =>
  Object.keys(obj) as (keyof T)[];

/** Deep paths version of {@link keys}
 * @returns deep path strings i.e. `['foo.bar', 'foo.baz']`
 */
export const keysDeep = <T extends Record<string, any>>(
  obj: T,
): FieldPath<T>[] => {
  return _keys(obj) as FieldPath<T>[];
};

/** Typed Object.entries */
export const entries = <T extends object>(obj: T): Entries<Required<T>> => {
  return Object.entries(obj) as Entries<T>;
};

/** Typed Object.entries filtering out undefined values */
export const definedEntries = <T extends object>(
  obj: T,
): Entries<Required<T>> => {
  return Object.entries(obj).filter(([k, v]) => v !== undefined) as Entries<
    Required<T>
  >;
};

/**
 * Gets deeply nested value from an object at path
 *
 * @param object Base object
 * @param path Path to value in dot notation. Arrays can be accessed by index
 * without brackets (e.g. 'a.b.0.c')
 * @returns typed value at path
 *
 * @example
 * const obj = { a: { b: [ { c: 1 }, {c: 2} ] } };
 * getValue(obj, 'a.b.0.c'); // 1
 * getValue(obj, 'a.b.1.c'); // 2
 */
export const getValue = <const O extends FieldValues, P extends FieldPath<O>>(
  object: O,
  path: P,
): FieldPathValue<O, P> => {
  const val = get(object, path);
  return val;
};

/**
 * Gets deeply nested value from an object at path and sets with default value
 * if it does not already exist in the object (default nullish).
 * *Always* mutates the input obj when setting.
 * @param object
 * @param path
 * @param value
 * @param opts.cloneValue [Optional] When true, the value will be *shallow* cloned
 * i.e. when passing in a reference to another object. (Default: `false`)
 * @param opts.predicate [Optional] Predicate function to determine if the value
 * should be set. i.e. `is.undefined` or `is.falsy` (Default: `(v): v is null | undefined)`
 * @returns The value at the path or default. Note: this is different from
 * lodoash & radash `set` behavior where the entire object is returned.
 */
export const getOrSetValue = <
  O extends FieldValues,
  const P extends FieldPath<O>,
  V extends FieldPathValue<O, P>,
>(
  object: O,
  path: P,
  defaultVal: V,
  {
    cloneValue = false,
    predicate,
  }: { cloneValue?: boolean; predicate?: (val: V) => boolean } = {},
): V => {
  const doSetPredicate = predicate ?? is.nullOrUndefined;
  const curVal = getValue(object, path);

  if (doSetPredicate(curVal)) {
    const setVal = setValue(object, path, defaultVal, { cloneValue }); // if ever switching from lodash, make sure new lib still mutates object
    return setVal;
  }
  return curVal;
};

/**
 * Gets deeply nested value from an object at path and sets with default value
 * if it does not already exist in the object (default nullish).
 * *Always* mutates the input obj when setting.
 * @param object
 * @param path
 * @param callback Only called if the value at path does not exist i.e. for
 * memozing getters
 * @param opts.predicate [Optional] Predicate function to determine if the value
 * should be set. i.e. `is.undefined` or `is.falsy` (Default: `(v): v is null | undefined)`
 * @returns The value at the path or default. Note: this is different from
 * lodoash & radash `set` behavior where the entire object is returned.
 */
export const getOrSetCallback = <
  O extends FieldValues,
  const P extends FieldPath<O>,
  V extends FieldPathValue<O, P>,
>(
  object: O,
  path: P,
  callback: () => V,
  { predicate }: { cloneValue?: boolean; predicate?: (val: V) => boolean } = {},
): V => {
  const doSetPredicate = predicate ?? is.nullOrUndefined;
  const curVal = getValue(object, path);

  if (doSetPredicate(curVal)) {
    const setVal = setValue(object, path, callback()); // if ever switching from lodash, make sure new lib still mutates object
    return setVal;
  }
  return curVal;
};

/**
 * Sets deeply nested value in an object at path. *Always* mutates input object.
 * @param object
 * @param path
 * @param value
 * @param opts.cloneValue [Optional] When true, the value will be *shallow* cloned i.e. when passing in a reference to another object. (Default: `false`)
 * @returns The value at the path or default. Note: this is different from
 * lodash & radash `set` behavior where the entire object is returned.
 */
export const setValue = <
  O extends FieldValues,
  P extends FieldPath<O>,
  V extends FieldPathValue<O, P>,
>(
  object: O,
  path: P,
  value: V,
  {
    cloneValue = false,
  }: {
    cloneValue?: boolean;
  } = {},
): V => {
  let setVal = value;

  if (cloneValue) {
    setVal = clone(value);
  }
  set(object, path, setVal);

  return get(object, path);
};

/** Sets all supplied keys to a default value. Uses lodash `_.set` and will
create deeply nested paths including arrays if they do not already exist
 *
 * @param obj
 * @param paths Array of deep key paths
 * @param value Value to set all properties at keys to, must be valid
   (intersection of) of all property types at all paths
 * @param opts.mutate [Optional] When true, the passed in object will be mutated. If false it will
 * first be *shallow* cloned (Default: `true`)
 * @param opts.cloneValue [Optional] When true, the value will be *shallow* cloned
   before being set, i.e. when passing in a reference to another object. (Default: `false`)

 * @returns The entire object, either passed in or cloned
 */
export const setValues = <O extends FieldValues, P extends FieldPath<O>>(
  obj: O,
  paths: P[],
  value: UnionToIntersection<FieldPathValue<O, P>>,
  {
    mutate = true,
    cloneValue = false,
  }: {
    mutate?: boolean;
    cloneValue?: boolean;
  } = {},
): O => {
  let setObj = obj;
  let setVal = value;
  if (mutate) {
    setObj = clone(obj);
  }
  if (cloneValue) {
    setVal = clone(value);
  }
  for (const path of paths) {
    set(setObj, path, setVal);
  }
  return setObj;
};
